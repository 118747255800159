<template>
  <ion-page class="page scrollable p-0 pb-5" style="padding: 0 !important">
    <div class="sticky-warning" v-if="showNsfwWarning">
      <ion-card class="m-0 warning sticky-warning">
        <div class="d-flex align-items-center justify-content-center text-color" style="height: 35px">
          <ion-icon class="mr-1" style="font-size: 18px !important" :icon="warningOutline" />
          <strong> This is a mature profile, you're viewing limited content</strong>
        </div>
      </ion-card>
    </div>
    <ProfileHead :showNsfwWarning="showNsfwWarning" @editProfile="isEditingProfile = true" />
    <div class="follow d-flex justify-content-end w-100">
      <div
        v-if="isAuthenticated && !isLoading && !isProfileOwner && !isFollowLoading"
        class="d-flex buttons align-items-center w-100 mt-2 px-3"
      >
        <div v-if="!showNsfwWarning || (showNsfwWarning && followStatus.type)" class="follow mt-2 mr-2">
          <div v-if="get(user, 'is_email_verified', true)">
            <fwb-dropdown :text="userFollowStatus" placement="bottom" close-inside>
              <template #trigger>
                <ion-button
                  v-if="get(user, 'is_email_verified', true)"
                  class="button-size m-0"
                  :color="isStatusChecked ? 'medium' : 'primary'"
                  >{{ userFollowStatus }}
                </ion-button>
              </template>
              <fwb-list-group class="rounded-lg">
                <fwb-list-group-item
                  class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
                  @click="updateFollowStatus('superfollow')"
                  v-if="followType !== 'superfollow'"
                >
                  <div class="menu-item">
                    <p class="my-2">
                      SuperFollow
                      <br /><small>(<i class="ti-bell" /> + priority on feed)</small>
                    </p>
                  </div>
                </fwb-list-group-item>
                <fwb-list-group-item
                  class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
                  @click="updateFollowStatus('follow')"
                  v-if="followType !== 'follow'"
                >
                  <div class="menu-item">
                    <p class="my-2">
                      Follow
                      <br /><small>(<i class="ti-bell" style="font-size: 14px" /> + on feed)</small>
                    </p>
                  </div>
                </fwb-list-group-item>
                <fwb-list-group-item
                  class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
                  @click="updateFollowStatus('litefollow')"
                  v-if="followType !== 'litefollow'"
                >
                  <div class="menu-item">
                    <p class="my-2">
                      LiteFollow <br />
                      <small>(not on feed)</small>
                    </p>
                  </div>
                </fwb-list-group-item>
                <hr class="border-gray-200 dark:border-gray-600" v-if="isStatusChecked" />
                <fwb-list-group-item
                  class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
                  v-if="isStatusChecked"
                  @click="updateFollowStatus('unfollow')"
                >
                  <div class="menu-item w-100">
                    <div class="bottom-button">Unfollow</div>
                  </div>
                </fwb-list-group-item>
              </fwb-list-group>
            </fwb-dropdown>
          </div>
          <ion-button
            v-else
            class="button-size m-0"
            :color="isStatusChecked ? 'medium' : 'primary'"
            @click="openVerificationModal"
            >{{ userFollowStatus }}
          </ion-button>
        </div>

        <fwb-dropdown placement="left">
          <template #trigger>
            <ion-button class="more-btn" style="--background: #e4e6eb; color: #242629; --border-radius: 10px">
              <i class="ti-more-alt" />
            </ion-button>
          </template>
          <fwb-list-group class="rounded-lg follow-menu">
            <fwb-list-group-item
              @click="blockUser"
              class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
            >
              <div class="menu-item d-flex align-items-center no-select">
                <span>Block User</span>
              </div>
            </fwb-list-group-item>

            <fwb-list-group-item
              @click="report"
              class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
            >
              <ion-item class="item-container d-flex pointer no-select">
                <div class="menu-item d-flex align-items-center no-select text-danger">
                  <span>Report User</span>
                </div>
              </ion-item>
            </fwb-list-group-item>
          </fwb-list-group>
        </fwb-dropdown>
      </div>
    </div>
    <div v-if="currentProfile" class="rest px-md-3 px-2 pb-5">
      <AboutUser class="mt-4" />
      <div v-if="!showNsfwWarning" class="w-100">
        <Achievements class="mt-4" />
        <FeaturedCharacters class="mt-4" />
        <FeaturedFolders class="mt-4" />
        <UserPosts class="mt-4" />
        <UserMarketPlaceListing class="mt-4" />
        <Creations id="creations" class="mt-4" :folders="folders" />
        <UserProfileComments @loading="(val) => (loadingComments = val)" class="mt-4" />
      </div>
    </div>
    <EditProfileModal :is-open="isEditingProfile" @dismissModal="isEditingProfile = false" />
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController, modalController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';
import { follow, getStatusOfFollowing, unfollowUser, updateFollowType } from '@/shared/actions/follow';
import { getUserContentWarning } from '@/shared/actions/users';
import { blockEntity } from '@/shared/actions/blockedEntities';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import { warningOutline } from 'ionicons/icons';
import UserProfileComments from './components/UserProfileComments.vue';
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue';
import { getProfileFolders } from '@/shared/actions/collections';
import { Paging, Collection } from '~/shared/types/static-types';
import ReportModalNew from '~/shared/modals/ReportModalNew.vue';

const UserPosts = defineAsyncComponent(() => import('./components/UserPosts.vue'));
const ProfileHead = defineAsyncComponent(() => import('./components/ProfileHead.vue'));
const FeaturedCharacters = defineAsyncComponent(() => import('./components/FeaturedCharacters.vue'));
const Achievements = defineAsyncComponent(() => import('./components/Achievements.vue'));
const Creations = defineAsyncComponent(() => import('./components/Creations.vue'));
const UserMarketPlaceListing = defineAsyncComponent(() => import('./components/UserMarketPlaceListings.vue'));
const AboutUser = defineAsyncComponent(() => import('./components/AboutUser.vue'));
const EditProfileModal = defineAsyncComponent(() => import('./modals/EditProfileModal.vue'));
const FeaturedFolders = defineAsyncComponent(() => import('./components/FeaturedFolders.vue'));

const picture = ref('');
const loadingComments = ref(false);
const title = ref('');
const userWarning = ref();
const description = ref('');
const pageTitle = ref('');
const allowCrawlers = ref(true);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);
const { isAuthenticated } = authStore();
const isBlocking = ref(false);
const isFollowLoading = ref(false);
const foldersPage = ref(1);
const folders = ref<Collection[]>([]);
const foldersPaging = ref<Paging>();
const showNsfwWarning = computed(() => {
  if (user.value.id === currentProfile.value?.id) return false;

  return !user.value.show_nsfw && currentProfile.value?.is_nsfw;
});

const report = async () => {
  const modal = await modalController.create({
    component: ReportModalNew,
    cssClass: 'report-modal',
    componentProps: {
      reportEntityId: currentProfile.value?.id,
      reportEntityType: 'user',
      reportedUser: currentProfile.value,
    },
  });
  await modal.present();
};

const {
  isLoading,
  reset,
  resetStuff,
  loadProfile,
  loadProfileStuff,
  loadProfileOwnerStuff,
  followersCount,
  currentProfile,
  isProfileOwner,
} = profileStore();

const loading = computed(() => isLoading.value || loadingComments.value);

useCommentHighlighter(loading);

const { user } = authStore();

const isEditingProfile = ref(false);
const isStatusChecked = ref(false);
const followType = ref('');
const followStatus = ref({}) as any;

const updatedFollow = async () => {
  if (followType.value === 'unfollow') {
    isStatusChecked.value = false;
    await unfollowUser(followStatus.value.id);
    followStatus.value = {};
  } else {
    await updateFollowType(followStatus.value.id, {
      type: followType.value,
    });
    followStatus.value.type = followType.value;
    isStatusChecked.value = true;
  }
};

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const loadFolders = async () => {
  if (!user.value?.username) return;

  const { results, ...rest } = await getProfileFolders(user.value?.username, foldersPage.value);

  foldersPaging.value = rest;
  folders.value = [...folders.value, ...results];
};

onMounted(async () => {
  await loadFolders();
});

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const getFollowStatus = async () => {
  isFollowLoading.value = true;
  followStatus.value = {};
  const resp = await getStatusOfFollowing(currentProfile.value?.id, 'user');
  followStatus.value = resp || {};
  if (!isEmpty(followStatus.value.type)) {
    isStatusChecked.value = true;
  }
  isFollowLoading.value = false;
};

const updateFollowStatus = async (type: string) => {
  followType.value = type;
};

const userFollowStatus = computed(() => {
  return followStatus.value?.type === 'superfollow'
    ? 'Super Following '
    : followStatus.value?.type === 'follow'
    ? 'Following'
    : followStatus.value?.type === 'litefollow'
    ? 'Lite Following'
    : 'Follow';
});

const doFollow = async () => {
  if (followType.value === 'unfollow') return;

  const response = await follow('user', currentProfile.value?.id, followType.value);
  followStatus.value = response;
  isStatusChecked.value = true;
};

watch(followType, () => {
  followStatus.value.type ? updatedFollow() : followType.value ? doFollow() : '';
});

watch(currentProfile, () => {
  if (isAuthenticated.value && !isProfileOwner.value && get(currentProfile.value, 'id')) getFollowStatus();
});

const pageUsername = computed(() => {
  const router = useRouter();
  return router.currentRoute.value.params.username as string;
});

const usernameToLookup = computed(() => (pageUsername.value || user.value.username) as string);

watch(user, () => {
  const router = useRouter();
  if (
    (router.currentRoute.value.name === 'profile-self' || router.currentRoute.value.name === 'profile') &&
    !currentProfile.value
  ) {
    loadProfile(usernameToLookup.value);
  }
});

watch(currentProfile, async () => {
  resetStuff();
  await loadProfileStuff();
  await loadProfileOwnerStuff();

  try {
    const router = useRouter();

    if (router.currentRoute.value.query.creations) {
      const creations = document.getElementById('creations');
      creations?.scrollIntoView({ behavior: 'smooth' });
    }
  } catch (e) {}
});

const fetchUserContentWarning = async (username: string) => {
  const resp = await getUserContentWarning(username);
  userWarning.value = resp;
};

const nsfwWarning = async () => {
  if (currentProfile.value && userWarning.value) {
    if (!isAuthenticated.value && userWarning.value.content_warning) {
      const alert = await alertController.create({
        cssClass: 'char-warning-alert',
        header: 'Warning',
        message: `You are about to see potentially mature content, by proceeding you confirm you are 18 years of age or older.`,
        buttons: [
          {
            text: 'Continue',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'mx-auto',
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
    }
  }
};

await useChAsyncData(async () => {
  reset();

  allowCrawlers.value = followersCount.value > 1000;
  pageTitle.value = `${usernameToLookup.value}'s profile - CharacterHub`;
  description.value = `See more about ${usernameToLookup.value} on CharacterHub!`;
  title.value = `${usernameToLookup.value}`;

  const profile = await loadProfile(usernameToLookup.value);
  picture.value = profile?.profile_picture_url || '';
  useChHead(pageTitle, title, description, url, picture, allowCrawlers, undefined);
  await fetchUserContentWarning(profile?.username || usernameToLookup.value);
  if (process.client) await nsfwWarning();
});

const blockUser = async () => {
  if (isBlocking.value) return;

  isBlocking.value = true;

  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to block <strong>${currentProfile.value?.username}</strong>.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-medium',
          id: 'cancel-button',
        },
        {
          text: 'Block',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role === 'ok') {
      await blockEntity('user', currentProfile.value?.id || '');
      toast.show(`${currentProfile.value?.username} has been blocked.`, 'nonative', 'success');
      const router = useRouter();
      router.push({ name: 'home' });
    }
  } catch (err) {
    toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }

  isBlocking.value = false;
};
onMounted(() => {
  if (get(route.query, 'itemId')) {
    isEditingProfile.value = true;
  }
});
</script>

<style lang="sass" scoped>
.follow-menu
  li
    justify-content: center
    height: 40px
    *
      font-size: 15px !important
  ion-item
    --background: transparent
.more-btn
  height: 32px
.buttons
  justify-content: flex-end !important
  @media(max-width: 500px)
    justify-content: space-between !important
.block-profile-btn
  font-weight: 400 !important
  min-width: 100px !important
  text-decoration: underline
.button-size
  min-width: 140px
  height: 32px
  text-transform: unset
  font-weight: bold
  --border-radius: 10px
.followers-loading
  width: 90px
  height: 40px
.page
  overflow-x: hidden !important
  padding: 0 !important
.v-enter-active,
.v-leave-active
  transition: opacity 1s ease

.v-enter-from,
.v-leave-to
  opacity: 0
</style>
